import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate, useNavigate, useLocation } from 'react-router-dom';
import BouquetPage from './BouquetPage';
import CakePage from './CakePage';
import MessagePage from './MessagePage';
import GiftPage from './GiftPage';
import EmailVerificationPage from './EmailVerificationPage';
import './App.css';

function App() {
  const [isEmailVerified, setEmailVerified] = useState(false);
  const [verificationStatus, setVerificationStatus] = useState(null); // Track status of email verification
  const navigate = useNavigate();
  const location = useLocation(); // Used to get query params for verification code

  useEffect(() => {
    // If we're on /gift, check the verification status from the URL
    if (location.pathname === "/gift" && !isEmailVerified) {
      const queryParams = new URLSearchParams(location.search);
      const verificationCode = queryParams.get("code");

      if (verificationCode) {
        // Call server to verify the email code
        verifyEmail(verificationCode);
      }
    }
  }, [location.pathname]);

  const verifyEmail = async (code) => {
    try {
      const response = await fetch(`https://btest.infinityfreeapp.com/verify.php?code=${code}`);
      const data = await response.json();

      if (data.status === 'verified') {
        setEmailVerified(true); // Update the state to allow access to /gift
        navigate('/gift');
      } else {
        setVerificationStatus(data.status); // Update status for error handling
        navigate('/email'); // Redirect to email page if failed
      }
    } catch (error) {
      console.error("Error verifying email:", error);
      setVerificationStatus('error');
      navigate('/email');
    }
  };

  const handleNextPage = (currentPage) => {
    switch (currentPage) {
      case '/':
        navigate('/cake');
        break;
      case '/cake':
        navigate('/message');
        break;
      case '/message':
        navigate('/email');
        break;
      case '/email':
        setEmailVerified(true);
        navigate('/gift');
        break;
      default:
        navigate('/');
        break;
    }
  };

  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<BouquetPage onNext={() => handleNextPage('/')} />} />
        <Route path="/cake" element={<CakePage onNext={() => handleNextPage('/cake')} />} />
        <Route path="/message" element={<MessagePage onNext={() => handleNextPage('/message')} />} />
        <Route
          path="/email"
          element={<EmailVerificationPage onNext={() => handleNextPage('/email')} />}
        />
        <Route
          path="/gift"
          element={
            isEmailVerified ? <GiftPage /> : <Navigate to="/email" replace />
          }
        />
        <Route path="*" element={<Navigate to="/" replace />} />
      </Routes>
    </div>
  );
}

// Wrap App in Router
export default function WrappedApp() {
  return (
    <Router>
      <App />
    </Router>
  );
}
