// src/CakePage.js
import React, { useState, useEffect, useRef } from 'react';
import Confetti from 'react-confetti';
import { motion } from 'framer-motion'; // For animations
import Fireworks from 'fireworks-js'; // Import fireworks-js
import './CakePage.css'; // Import the Cake Page CSS

const CakePage = ({onNext}) => {
  const [windowDimensions, setWindowDimensions] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  const [displayedMessage, setDisplayedMessage] = useState(""); // State to hold the displayed message
  const [isConfettiActive, setIsConfettiActive] = useState(false); // State to control confetti visibility
  const message = "Happy Birthday test"; // Change this to your desired message
  const fireworksContainerRef = useRef(null); // Create a ref for the fireworks container

  useEffect(() => {
    const fireworks = new Fireworks(fireworksContainerRef.current, {
      autoresize: true,
      opacity: 0.5,
      acceleration: 1.05,
      friction: 0.97,
      gravity: 1.5,
      particles: 50,
      traceLength: 3,
      traceSpeed: 10,
      explosion: 5,
      intensity: 30,
      flickering: 50,
      lineStyle: 'round',
      hue: {
        min: 0,
        max: 360,
      },
      delay: {
        min: 30,
        max: 60,
      },
      rocketsPoint: {
        min: 50,
        max: 50,
      },
      lineWidth: {
        explosion: {
          min: 1,
          max: 3,
        },
        trace: {
          min: 1,
          max: 2,
        },
      },
      brightness: {
        min: 50,
        max: 80,
      },
      decay: {
        min: 0.015,
        max: 0.03,
      },
      mouse: {
        click: false,
        move: false,
        max: 1,
      },
    });

    fireworks.start(); // Start the fireworks

    
  }, []); // Empty dependency array ensures this effect runs once

  useEffect(() => {
    const handleResize = () => {
      setWindowDimensions({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    // Delay starting confetti for 1 second after fireworks start
    const confettiTimeout = setTimeout(() => {
      setIsConfettiActive(true);
    }, 1000);

    return () => clearTimeout(confettiTimeout); // Cleanup on unmount
  }, []); // Run only once

  useEffect(() => {
    let index = 0; // Reset index for each new message
    const intervalId = setInterval(() => {
      if (index < message.length) {
        const currentChar = message.charAt(index); // Get the current character
        setDisplayedMessage(prev => prev + currentChar); // Append one character at a time

        index++; // Move to the next character
      } else {
        clearInterval(intervalId); // Clear the interval when the message is fully displayed
      }
    }, 150); // Adjust the speed of typing by changing this value

    return () => clearInterval(intervalId); // Clear the interval on component unmount
  }, [message]);

  return (
    <div className="cake-page" style={{ position: 'relative' }}>
      <div ref={fireworksContainerRef} style={{ position: 'absolute', width: '100%', height: '100%', top: 0, left: 0 }} />
      
      {/* Confetti Animation */}
      {isConfettiActive && (
        <Confetti 
          width={windowDimensions.width} 
          height={windowDimensions.height} 
          recycle={true} // Set to true for continuous confetti
          numberOfPieces={100} // Adjust the number of pieces as needed (lower for slower)
          style={{ position: 'absolute', zIndex: 1 }} // Ensure confetti is above the background
        />
      )}

      {/* Birthday Message */}
      <div className="birthday-message">{displayedMessage}</div>

      {/* Cake Animation */}
      <motion.div
        className="cake"
        initial={{ scale: 0 }}
        animate={{ scale: 1 }}
        transition={{ duration: 2, ease: 'easeOut' }}
      >
        <div className="cake-body">🎂</div>
        <div className="candles">
          <span className="candle">3</span>
          <span className="candle">3</span>
        </div>
      </motion.div>

      {/* Birthday Sound */}
      <audio autoPlay>
        <source src="/birthday-song.mp3" type="audio/mp3" />
      </audio>

      {/* Next Button */}
      <motion.button
        className="next-button"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.5, delay: 2 }} // Delay to allow animations to complete
        onClick={onNext}
      >
        Next
      </motion.button>
    </div>
  );
};

export default CakePage;
