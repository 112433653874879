import React, { useState } from 'react';
import './GiftPage.css';

const GiftPage = () => {
    const [isPopupOpen, setPopupOpen] = useState(false);
    const [currentGift, setCurrentGift] = useState(null);
    const [openingGift, setOpeningGift] = useState(null);
    const [sparklesActive, setSparklesActive] = useState(false); // State to toggle sparkles
    const [openedGifts, setOpenedGifts] = useState([false, false, false]); // Track which gifts have been opened

    const handleGiftClick = (giftNumber) => {
        // Sort the gifts by value, and find the gift with the lowest value that's not opened yet
        const sortedGifts = Object.keys(giftData)
            .map(key => ({ key, ...giftData[key] }))
            .filter(gift => !openedGifts[parseInt(gift.key.replace('gift', '')) - 1]) // Filter out opened gifts
            .sort((a, b) => a.value - b.value); // Sort by value (lowest to highest)

        const lowestValueGift = sortedGifts[0]; // Get the lowest value gift

        // Open the lowest value gift, but keep the clicked one visually active
        const giftNumberToOpen = parseInt(lowestValueGift.key.replace('gift', ''));

        setOpeningGift(giftNumberToOpen); // Start the animation for the clicked gift
        setTimeout(() => {
            const newOpenedGifts = [...openedGifts];
            newOpenedGifts[giftNumberToOpen - 1] = true; // Mark the lowest value gift as opened
            setOpenedGifts(newOpenedGifts);
            setOpeningGift(null);
            setCurrentGift(lowestValueGift.key); // Set the current gift to the lowest value gift
            setPopupOpen(true);
            setSparklesActive(true); // Activate sparkles when gift is opened
        }, 800);
    };

    const handleClosePopup = () => {
        setPopupOpen(false);
        setSparklesActive(false); // Deactivate sparkles when popup is closed
    };

    const giftData = {
        gift1: { name: 'Gift Card 1', value: 50, imageUrl: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ6POT_9TleyuheADXbnueHdTjGlkUP8iK6hA&s' },
        gift2: { name: 'Gift Card 2', value: 100, imageUrl: 'https://link-to-image.com/giftcard2.jpg' },
        gift3: { name: 'Gift Card 3', value: 150, imageUrl: 'https://link-to-image.com/giftcard3.jpg' },
    };

    return (
        <div className="gift-page">
            <h1>Unwrap Your Surprises!</h1>
            <div className="gift-box-container">
                {[1, 2, 3].map((giftNumber) => (
                    <div
                        key={giftNumber}
                        className={`gift-box ${openingGift === giftNumber ? 'opening' : ''} ${openedGifts[giftNumber - 1] ? 'opened' : ''} gift-box-${giftNumber}`}
                        onClick={() => handleGiftClick(giftNumber)}
                    >
                        <div className={`ribbon ${openedGifts[giftNumber - 1] ? 'opened' : ''}`}></div>
                        <span>Gift {giftNumber}</span>
                    </div>
                ))}
            </div>

            {isPopupOpen && (
                <div className="popup-overlay" onClick={handleClosePopup}>
                    <div className="popup-content" onClick={(e) => e.stopPropagation()}>
                        <img
                            src={giftData[currentGift].imageUrl}
                            alt={giftData[currentGift].name}
                            className="gift-image"
                        />
                        <p>{giftData[currentGift].name}</p>
                        <p>${giftData[currentGift].value}</p> {/* Displaying dollar value */}
                        <button onClick={handleClosePopup} className="next-button">Close</button>
                    </div>
                </div>
            )}

            {/* Sparkles */}
            <div className={`sparkles ${sparklesActive ? 'active' : ''}`}></div>
        </div>
    );
};

export default GiftPage;
