// src/BouquetPage.js
import React, { useEffect, useState } from 'react';
import { motion } from 'framer-motion';
import './BouquetPage.css'; // Ensure this file exists

const BouquetPage = ({ onNext }) => {
  const [showButton, setShowButton] = useState(false);
  const [animateHeart, setAnimateHeart] = useState(false);

  useEffect(() => {
    const flowerTimer = setTimeout(() => {
      setAnimateHeart(true); // Trigger heart animation after flower animation
    }, 4000); // Adjust time as needed

    // Show the button after heart animation
    const buttonTimer = setTimeout(() => {
      setShowButton(true);
    }, 6000); // Show button 2 seconds after heart animation

    return () => {
      clearTimeout(flowerTimer);
      clearTimeout(buttonTimer);
    };
  }, []);

  return (
    <div className="bouquet-page">
      {/* Flower Animation */}
      <div className="flower-container">
        {[...Array(5)].map((_, index) => (
          <motion.div
            className="flower"
            key={index}
            initial={{ scale: 0, opacity: 0, y: 100 }}
            animate={{ scale: 1, opacity: 1, y: 0 }}
            transition={{ duration: 2, delay: index * 0.5 }} // Stagger the bloom effect
          >
            <div className="stem"></div>
            <div className="rose"></div>
          </motion.div>
        ))}
      </div>

      {/* Heart Animation */}
      <motion.div
        className={`heart-shape ${animateHeart ? 'animate' : ''}`}
        initial={{ y: '-100vh', opacity: 0 }}
        animate={animateHeart ? { y: 0, opacity: 1 } : {}}
        transition={{ duration: 1 }}
      >
        ❤️
      </motion.div>

      {/* Button to Cake Page */}
      {showButton && (
        <motion.button
        className="next-button"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.5 }}
        onClick={() => {
          setShowButton(false); // Hide button
          setTimeout(() => onNext(), 1000); // Delay the transition
        }}
      >
        Next
      </motion.button>
      
      )}
    </div>
  );
};

export default BouquetPage;
