// src/EmailVerificationPage.js
import React, { useState } from 'react';
import './EmailVerificationPage.css'; // CSS for styling

const EmailVerificationPage = ({ onVerified }) => {
  const [email, setEmail] = useState('');
  const [statusMessage, setStatusMessage] = useState('');

  const handleEmailSubmit = async () => {
    try {
      const response = await fetch('https://btest.infinityfreeapp.com/verify-email.php', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ email }),
      });
      const result = await response.json();
      if (result.success) {
        setStatusMessage('Verification email sent! Check your inbox.');
      } else {
        setStatusMessage(result.message || 'Something went wrong.');
      }
    } catch (error) {
      setStatusMessage('Error sending verification email.');
    }
  };

  return (
    <div className="email-verification-page">
      <h1>Verify Your Email</h1>
      <p>Please enter your email address to access your gift.</p>
      <input
        type="email"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        placeholder="Enter your email"
        className="email-input"
      />
      <button className="send-email-button" onClick={handleEmailSubmit}>
        Send Verification Email
      </button>
      {statusMessage && <p className="status-message">{statusMessage}</p>}
    </div>
  );
};

export default EmailVerificationPage;
