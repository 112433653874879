import React from 'react';
import { motion } from 'framer-motion';
import './MessagePage.css';

const MessagePage = ({ onNext }) => {
  // Predefined messages
  const messages = [
    "Wishing you a day filled with love and cheer!",
    "Happy Birthday! May all your wishes come true!",
    "Another year older, but always young at heart!",
    "Cheers to you on your special day!",
    "Hope your birthday is as amazing as you are!",
  ];

  // Randomly select a message
  const randomMessage = messages[Math.floor(Math.random() * messages.length)];

  return (
    <div className="message-page">
      <h2>Birthday Wishes!</h2>

      <motion.div
        className="displayed-message"
        initial={{ opacity: 0, scale: 0.8 }} // Initial animation state
        animate={{ opacity: 1, scale: 1 }} // Final animation state
        transition={{ duration: 0.5 }} // Animation duration
      >
        <h3>A Message To You:</h3>
        <p>{randomMessage}</p>
      </motion.div>

      <motion.button
        className="next-button"
        onClick={onNext}
        style={{ marginTop: '20px' }} // Add some margin for spacing
      >
        Next
      </motion.button>
    </div>
  );
};

export default MessagePage;
